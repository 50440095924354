.created-by{
    color: rgb(99, 99, 99);
    font-size: small;
    float: right;
}
.Show-more{
    background-color: #ff7300;
    border-color: #ff7300;
    color: white;
}
.Show-more:hover{
    background-color: #e96900;
    border-color: #ff7300;
    color: white;
}
.show-more-div{
    background-color: rgb(235, 236, 237);
    margin-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 5px;
}
