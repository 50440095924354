.navbar-brand{
    color: white;
}
.navbar-brand:hover{
    color: white;
}
.navbar{
    background-color:#212A30 ;
    padding-top: 7px; 
}
