.search-bar{
    border-radius: 100px;
}
.form-control-borderless {
    border: none;
}

.form-control-borderless:hover, .form-control-borderless:active, .form-control-borderless:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.btn-success:hover {
    color: #fff;
    background-color: orange;
    border-color: orange;
}

.btn-success {
    color: #0d6efd;
    background-color: transparent;
    border-color: #0d6efd;
}
.magnify{
    height: 2rem;
    width: 2rem;
    
}
