
.case-wrapper {
   display: grid;
   height: 100vh;
   width: 100vw;
   grid-template: 1fr 10fr 1fr/1fr 12fr 1fr;
   gap: 10px;
   grid-template-areas:
       "case-header case-header case-header"
       "case-space-left case-cont case-space-right"
       "case-footer case-footer case-footer"
   ;
}

.case-space-left {
   grid-area: case-space-left;
}

.case-space-right {
   grid-area: case-space-right;
}

.case-header {
   grid-area: case-header;
}

.case-cont {
   grid-area: case-cont;
   margin: 0.25rem;
   overflow: auto;
}

.case-footer {
   grid-area: case-footer;
   background: aliceblue;
}

.case-view-box {
   overflow-x: hidden;
   overflow-y: hidden;
}

.overflow-override {
   overflow: scroll !important;
}

.reply-box {
   background-color: rgb(251, 251, 251);
}

.work-note-card {
   background-color: #fff1d4;
}

.case-text-area {
   height: 10vh;
}

.header-box {
   text-align: center;
   border-radius: 5px;
   border-color: rgb(247, 247, 247);
   background-color: transparent;
   padding: 1rem;
   color: #555555;
}

.header-box h3{
   color: black;
}

.header-item-right {
  display: block;
  text-align: left;
  width: 23%;
}

.header-item-left {
  display: block;
  text-align: left;
}

.title {
   background-color: #212A30;
   color: rgb(255, 254, 254);
   padding: 0.5rem;
   border-radius: 5px;
   text-align: center;
}

.attachment-items {
   background-color: rgb(167, 166, 166);
   color:rgb(0, 0, 0);
   padding: 0.1rem;
   padding-bottom: 0.3rem;
   border-radius: 5px;
   text-align: center;
}

.attachment-item-filename {
  margin-top: 0.5rem;
  margin-bottom: -0.2rem;
}
.attachment-item-time {
  display: inline;
  margin-left: 0.3rem;
  font-size: 0.9rem;
}

.attachment-card-body {
   padding: 0.5rem;
   cursor: pointer;
}

.attachment-items:hover {
   color: white;
   background-color: #ff7300;
}

.attachment-items-disabled {
   background-color: rgb(225, 225, 225);
   color:rgb(166, 166, 166);
   padding: 0.1rem;
   border-radius: 5px;
   text-align: center;
}

.attachment-not-found {
   color: rgb(139, 139, 139);
   text-align: center;
}
.worknoteInput{
   max-height: 8rem;
}
.add-worknote-btn{
   background-color: #ff7300;
   border-color: #ff7300;
   color: white;
}
.add-worknote-btn:hover{
   background-color: #e96900;
   border-color: #ff7300;
}
.cancel-worknote-btn{
   background-color: white;
   border-color: #ff7300;
   color: #ff7300;
   margin-left: 0.5rem;
}
.cancel-worknote-btn:hover{
   background-color: #e96900;
   border-color: #ff7300;
}

.col-lg-3 .p-4{
   padding: 0%;
}
