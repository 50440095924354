.login-panel {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
}

.user-avatar {
    margin-right: 1rem;
    border-radius: 1.25rem;
}

.avatar-button {
    align-items: center;
    font-size: 1.3rem;
    border: none;
    background: #212A30;
    display: flex;
    padding-right: 3rem;
}
.avatar-button:hover,
.avatar-button:active,
.avatar-button:focus {
    border-color: transparent;
}

.username-class {
    font-family: inherit;
    font-size: 1.25rem;
    line-height: 20px;
    color: black;
    margin-right: 1rem;
    align-items: center;
}

.mail-class {
    font-family: inherit;
    font-size: 0.75rem;
    line-height: 20px;
    color: black;
    margin-right: 1rem;
    width: 100%;
}
.title-class{
    color: white;
    font-size: 1.0rem;
    display: contents;
}

.dropdown-item{
    font-size: 0.75rem;
}

.dropdown-item:hover{
    cursor: pointer;
    background-color: #ffd2ae;
}

.dropdown-toggle::after{
    vertical-align: 0rem;
    color: white;
    size: 14;
}
