.list-group-items.active,
.list-group-sub-items.active,
.list-group-items:focus {
    background-color: #212A30;
    color: #ff7300;
}

.list-group-items{
  background-color: #212A30;
  color: white;
  border-bottom: 1px solid rgba(229, 225, 225, 0.125);
  text-decoration:none;
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative; 
  z-index: 1; 
}

.list-group-sub-items{
   background-color: #212A30;
   color: white;
   border-bottom: 1px solid rgba(229, 225, 225, 0.125);
   text-decoration:none;
   margin-top: -10px;
   padding-bottom: 8px;
   font-size: x-small;
   position: relative;
   z-index: 0; 
 }

.svg-icons.active,
.svg-icons:focus{
  background-color: #212A30;
  color: #ff7300;
}

.side-navbar{
  background-color: #212A30;
}

.nav-item{
  text-decoration:none;
}





