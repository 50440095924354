@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&family=Ruda&family=Source+Code+Pro:ital,wght@1,300&display=swap');

body {
  margin: 0;
  font-family: 'Ruda', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(236, 236, 236);
  overflow: hidden;
}

code {
  font-family: 'Source Code Pro', 'Courier Prime', monospace;
}

.ChoreoLogo{
  height: 2rem;
  margin-left: 0.5rem;
}

.AsgardeoLogo{
  height: 2rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
}
