:root {
  --glow-color: #f98d34;
}

.landing-center-box {
  font-size: 2.5rem;
  margin-top: auto;
  background-color: rgba(201, 201, 201, 0.348);
  width: 40rem;
  height: 30rem;
  padding: 40px 0;
  text-align: center;
  color: rgb(88, 92, 96);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.landing-left {
  max-height: 30rem;
  padding-right: 50px;
}

.landing-right {
  max-height: 30rem;
}

@media only screen and (max-height: 1000px) {

  .landing-center-box {
    font-size: 2.0rem;
    width: 30rem;
    height: 25rem;
    padding: 20px 0;
  }

  .landing-left {
    max-height: 25rem;
    padding-right: 20px;
  }

  .landing-right {
    max-height: 25rem;
  }
}

.landing-center-box p {
  margin-bottom: auto;
  margin-top: 1rem;
}

.landing-center-box h2 {
  margin-bottom: 1rem;
}

.landing-center-box img {
  margin-top: auto;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.column {
  float: left;
  width: 33.33%;
}

/* fancy login button */
.glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.35em 1em;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
  background: none;
  perspective: 2em;
  font-family: "Raleway", sans-serif;
  font-size: 0.5em;
  font-weight: 900;
  letter-spacing: 1em;
}

.glowing-txt {
  float: left;
  margin-right: -0.8em;
  animation: text-flicker 3s linear infinite;
  color: rgba(0, 0, 0, 0.8);
}

.glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(120%) rotateX(5deg) scale(1, 0.35);
  pointer-events: none;
}

.glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.glowing-btn:hover .glowing-txt {
  animation: none;
}

.glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 0.1;
  }

  4% {
    opacity: 0.5;
  }

  19% {
    opacity: 0.5;
  }

  21% {
    opacity: 0.1;
  }

  23% {
    opacity: 1;
  }

  80% {
    opacity: 0.5;
  }

  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }

  20% {
    opacity: 1;
  }

  25% {
    opacity: 0.3;
  }

  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }

  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }

  100% {
    opacity: 0.9;
  }
}
