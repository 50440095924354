.wrapper {
    display: grid;
    height: 100vh;
    width: 100vw;
    grid-template: auto 1fr auto/ 0.5fr 20fr;
    /* gap: 10px; */
    grid-template-areas:
        "header header"
        "nav cont"
        "footer footer"
    ;
}

.header {
    grid-area: header;
}

.navbar {
    background-color: #212A30;
}

.nav {
    grid-area: nav;
    font-size: 1.25rem;
    border-radius: 0%;
}

.cont {
    grid-area: cont;
    overflow: auto;
    margin: 1.25rem;
}

.footer {
    grid-area: footer;
    background: aliceblue;
}

.login-btn {
    background-color: #ff7300;
    border-color: #ff7300;
    color: white;
}

.login-btn:hover {
    background-color: #e96900;
    border-color: #ff7300;
    color: white;
}
