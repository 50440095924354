.card-hover:hover{
    background-color: #f9dcc5;
    cursor: pointer;
}

.search-result-body {
    padding: 0.5rem;
    padding-left: 2rem;
    border-radius: 2em;
}
